.select2 {
	max-width: 100%;
	display: block;
	
	&.select2-container--default {
		.select2-selection--single {
			border: 1px solid #000;
			height: auto;
			position: relative;
			display: block;
			font-weight: 400;
			padding: 18px 40px 18px 18px;
			border-radius: 7px;
			cursor: pointer;
			
			.cart_page & {
				padding: 9px 20px 9px 9px;
				@media screen and (min-width: 1366px) {
					padding: 14px 35px 14px 9px;
				}
			}
			
			.checkout_page & {
				border-color: #D0D0D0;
			}
			
			
			.select2-selection__rendered {
				color: #000;
				font-size: 16px;
				line-height: 127.84%;
				display: block;
				
				.checkout_page & {
					color: #8E8E8E;
				}
			}
			
			.select2-selection__arrow {
				position: initial;
				
				&:before {
					content: '';
					display: inline-block;
					mask-repeat: no-repeat;
					mask-position: center;
					mask-size: contain;
					mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-color: #000;
					width: 24px;
					height: 24px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%) rotate(0deg);
					transition: all 0.3s;
					
					.checkout_page & {
						background-color: #8E8E8E;
					}
					
					.cart_page & {
						right: 0px;
						@media screen and (min-width: 1366px) {
							right: 10px;
						}
					}
				}
				
				b {
					display: none;
				}
			}
		}
	}
	
	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				&:before {
					transform: translateY(-50%) rotate(-180deg);
				}
			}
		}
	}
}

span.select2-dropdown {
	display: block;
	
	&.select2-dropdown--above {
		transform: translateY(-10px);
	}
	
	&.select2-dropdown--below {
		transform: translateY(10px);
	}
	
	
	.select2-results {
		
		.select2-results__options {
			border: 1px solid #000000;
			border-radius: 7px;
			height: 180px;
			overflow: auto;
			
			.checkout_page & {
				border-color: #D0D0D0;
			}
			
			.select2-results__option {
				padding: 18px;
				font-size: 16px;
				line-height: 127.84%;
				transition: all 0.3s;
				cursor: pointer;
				background: #fff;
				
				&.select2-results__option--highlighted {
					background-color: #d7d7d7;
				}
				
				&.select2-results__option--selected {
					background-color: #000;
					color: #fff;
					
					i {
						&:before {
							background-color: #000000;
						}
					}
				}
			}
		}
	}
}

.select2-container--open {
	z-index: 50;
}

.select2-hidden-accessible, .select2-search--hide {
	display: none;
}