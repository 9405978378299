@tailwind base;
@tailwind components;
@tailwind utilities;

@import "components/select2";
@import "components/jquery-ui.css";
@import "components/jquery.timepicker.css";
@import "flatpickr.scss";
@import 'toastr';

@layer components {
	.btn-primary {
		@apply text-center rounded-7px bg-accent text-white text-xl uppercase font-bold p-5 w-full block;
	}
}
