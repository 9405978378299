// Import base styles
@import '~flatpickr/dist/flatpickr.min.css';

// Customise flatpickr
$calendarPadding: 24px;
$daySize: 36px;
$daysWidth: $daySize*7;

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
    border: inherit;
    @apply rounded shadow-lg border border-slate-200 left-1/2;
    margin-left: - ($daysWidth + $calendarPadding*2)*0.5;
    padding: $calendarPadding;
    width: $daysWidth + $calendarPadding*2;
}

@screen desktop {
    .flatpickr-calendar {
        @apply left-0 right-auto;
        margin-left: 0;
    }
}

.flatpickr-right.flatpickr-calendar {
    @apply right-0 left-auto;
    margin-left: 0;
}

.flatpickr-calendar.animate.open {
    animation: fpFadeInDown 200ms ease-out;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px);
}

.flatpickr-calendar.static.open {
    z-index: 20;
}

.flatpickr-wrapper {
   display: block;
}

.flatpickr-days {
    width: $daysWidth;
}

.dayContainer {
    width: $daysWidth;
    min-width: $daysWidth;
    max-width: $daysWidth;
}

.flatpickr-day {
    @apply bg-slate-50 text-sm font-medium text-slate-600;
    max-width: $daySize;
    height: $daySize;
    line-height: $daySize;
}

.flatpickr-day,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
    border: none;
}

.flatpickr-day,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
    border-radius: 0;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    @apply text-slate-400;
}

.rangeMode .flatpickr-day {
    margin: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    @apply bg-green-500 text-green-50;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    @apply bg-green-400 text-green-50;
}

.flatpickr-day.inRange,
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: none;
}

.flatpickr-months {
    align-items: center;
    margin-top: -8px;
    margin-bottom: 6px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    position: static;
    height: auto;
    @apply text-slate-600;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
    width: 7px;
    height: 11px;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit;
    @apply text-slate-400;
}

.flatpickr-months .flatpickr-prev-month {
    margin-left: -10px;
}

.flatpickr-months .flatpickr-next-month {
    margin-right: -10px;
}

.flatpickr-months .flatpickr-month {
    @apply text-slate-800;
    height: auto;
    line-height: inherit;
}

.flatpickr-current-month {
    @apply text-sm font-medium;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0;
}

.flatpickr-current-month span.cur-month {
    @apply font-medium m-0;
}

.flatpickr-current-month span.cur-month:hover {
    background: none;
}

.flatpickr-current-month input.cur-year {
    font-weight: inherit;
    box-shadow: none !important;
}

.numInputWrapper:hover {
    background: none;
}

.numInputWrapper span {
    display: none;
}

span.flatpickr-weekday {
    @apply text-slate-400 font-medium text-xs;
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after,
.flatpickr-calendar.arrowBottom::before,
.flatpickr-calendar.arrowBottom::after {
    display: none;
}

.cal_container .flatpickr-wrapper{
    width: 100% !important;
}
